<template>
  <div class="container">
    <div class="columns is-centered">
      <div class="column">
        <div class="content">
          <div class="column">
            <img
              style="margin-left: 30%;"
              src="../assets/PHDC-rental-repair-logo-x-450.jpg"
            />
            <br />
            <label
              ><em
                ><b
                  >Thank you for for your interest in the Rental Improvement
                  Fund. The first step is to create a Landlord Profile, below.
                  Afterwards, you will receive a confirmation email that
                  includes a link to submit a Rental Improvement Fund loan
                  application. If have any technical issues with the application
                  process, please email
                  <a href="mailto:RentalImprove@phila.gov"
                    >RentalImprove@phila.gov</a
                  ></b
                ></em
              ></label
            >
          </div>
          <ValidationObserver v-slot="{ errors }" tag="div">
            <input-form
              ref="form"
              :errors="errors"
              name="projectDocumentsFrm"
              @submit.prevent="toDemographicsPage"
            >
              <br />
              <h2><b>Landlord Profiles</b></h2>
              <div class="columns">
                <div class="column">
                  <vee-textbox
                    v-model="contactData.fName"
                    name="input-fName"
                    placeholder="First Name"
                    required
                    rules="required"
                    error="This field is required"
                  />
                </div>
                <div class="column">
                  <vee-textbox
                    v-model="contactData.lName"
                    name="input-lName"
                    placeholder="Last Name"
                    required
                    rules="required"
                    error="This field is required"
                  />
                </div>
              </div>

              <!-- <div class="columns">
                <div class="column">
                  <vee-textbox
                    v-model="contactData.dateOfBirth"
                    name="input-dateOfBirth"
                    placeholder="Date of Birth"
                    required
                    rules="required|date"
                    error="This field is required"
                  />
                </div>
              </div> -->
              <div class="columns">
                <div class="column">
                  <vee-dropdown
                    id="preferredContact"
                    v-model="contactData.preferredContact"
                    mode="eager"
                    name="dropdown-preferredContact"
                    :options="preferredOptions"
                    placeholder="Preferred Contact Method"
                    rules="required"
                    required
                    error="This field is required"
                  />
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <vee-textbox
                    id="email"
                    v-model="contactData.email"
                    mode="eager"
                    name="txt-email"
                    placeholder="Email Address"
                    rules="required|email"
                    required
                    error="This field is required"
                    @keydown="checkForCurrentProfile"
                    @change="checkForCurrentProfile"
                  />
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <vee-textbox
                    id="phone"
                    v-model="contactData.phoneNum"
                    mode="eager"
                    name="txt-phone-number"
                    placeholder="Phone Number"
                    rules="required|phone"
                    required
                    error="This field is required"
                  />
                </div>
                <div class="column">
                  <vee-textbox
                    id="phoneExt"
                    v-model="contactData.phoneExt"
                    mode="eager"
                    name="txt-phone-number-ext"
                    placeholder="Ext."
                    rules="numeric"
                    error="This field must be a number"
                  />
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <vee-radio
                    id="cityEmployee"
                    v-model="contactData.cityEmployee"
                    :options="radioOptions.YesNo"
                    mode="eager"
                    name="rd-city-employee"
                    label="Are you an employee of the Philadelphia Housing Development
                Corporation (PHDC) or the City of Philadelphia's Department of
                Planning and Development (DPD)?"
                    rules="required"
                    required
                    error="This field is required"
                  />
                </div>
              </div>
              <callout type="info">
                Please input an address where you can receive notices and
                materials from this program, not the address of your rental
                property unless you also live and receive mail there. PO Boxes
                are not allowed.
              </callout>
              <div>
                <h6><b>Address</b></h6>
                <div class="columns">
                  <div class="column">
                    <vee-textbox
                      id="address"
                      v-model="contactData.address.street1"
                      mode="eager"
                      name="txt-address-street1"
                      placeholder="Street Address"
                      rules="required"
                      required
                      error="This field is required"
                    />
                  </div>
                  <div class="column">
                    <vee-textbox
                      id="address-2"
                      v-model="contactData.address.street2"
                      name="txt-address-street2"
                      placeholder="Apt / Unit"
                    />
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <vee-textbox
                      id="city"
                      v-model="contactData.address.city"
                      mode="eager"
                      name="txt-address-city"
                      placeholder="City"
                      rules="required"
                      required
                      error="This field is required"
                    />
                  </div>
                  <div class="column">
                    <vee-dropdown
                      id="state"
                      v-model="contactData.address.state"
                      mode="eager"
                      name="txt-address-state"
                      :options="States"
                      placeholder="State"
                      rules="required"
                      required
                      error="This field is required"
                    />
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <vee-textbox
                      id="zip"
                      v-model="contactData.address.zipCode"
                      mode="eager"
                      name="txt-address-zip"
                      placeholder="Zip Code"
                      rules="required"
                      required
                      error="This field is required"
                    />
                  </div>
                </div>
              </div>
              <br />
              <div class="columns">
                <div class="column">
                  <vee-dropdown
                    id="RIFKnowledge"
                    v-model="RIFKnowledge"
                    mode="eager"
                    name="dropdown-RIFKnowledge"
                    :options="inqueryIncluded"
                    placeholder="How did you hear about RIF?"
                  />
                </div>
                <div v-if="RIFKnowledge == 'Other'" class="column">
                  <vee-textbox
                    id="RIFKnowledgeOther"
                    v-model="RIFKnowledgeOther"
                    mode="eager"
                    name="txt-RIFKnowledgeOther"
                    required
                    rules="required"
                    placeholder="If you selected other, please specify."
                  />
                </div>
              </div>
              <div v-if="buttonDisabled == true" class="columns">
                <div class="column">
                  <callout type="danger"
                    ><b
                      >You have already created a RIF profile. Please access
                      your existing profile using your email address and the
                      verification code, which was sent to you by email:
                      <a
                        href="https://rental-improvement.phdcphila.org/#/loandetails"
                        >https://rental-improvement.phdcphila.org/#/loandetails</a
                      >.</b
                    ></callout
                  >
                </div>
              </div>
              <div class="column is-2 is-offset-10">
                <button
                  type="button"
                  class="button is-primary"
                  @click="toDemographicsPage"
                  :disabled="buttonDisabled"
                >
                  Enter
                </button>
              </div>
            </input-form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import options from "@/utilities/options.js";
import axios from "axios";
import { delay } from "@/utilities/functions";
import buildProfileInfoObject from "@/utilities/get-current-profile";
import { getPropertyValueOrDefault } from "@/utilities/functions";

export default {
  name: "HomePage",
  mixins: [options],
  props: {
    miNumber: {
      default: null,
      type: String
    }
  },
  data() {
    return {
      ShowCandidateTable: false,
      AddressCandidatesLoaded: false,
      profileCheck: null,
      buttonDisabled: false,
      contactData: {
        fName: null,
        lName: null,
        address: {
          rawAddress: null,
          candidates: [],
          street1: null,
          street2: null,
          city: null,
          state: null,
          zipCode: null
        },
        email: null,
        phoneNum: null,
        phoneExt: null,
        cityEmployee: null,
        comments: null,
        RIFKnowledge: null,
        RIFKnowledgeOther: null,
        dateOfBirth: null,
        preferredContact: null //edit this
      }
    };
  },
  created() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  },
  methods: {
    checkForCurrentProfile() {
      var vData = this;
      const dataObject = buildProfileInfoObject(
        vData.contactData.email,
        "RIF_GET_PROFILE_INFO"
      );
      const requestObj = {
        table: "bs2bx26ex",
        data: dataObject
      };
      axios
        .post(
          "https://djql8jp3sb.execute-api.us-east-1.amazonaws.com/Rental-Improvement-Fund-Quickbase-Request-Proxy-Reads",
          requestObj
        )
        .then(response => {
          const responseObject = getPropertyValueOrDefault(
            ["data", "record", "f"],
            response
          );
          vData.profileCheck = getPropertyValueOrDefault(
            ["_text"],
            responseObject.find(i => i._attributes.id === "6")
          );

          if (vData.profileCheck != null) {
            vData.buttonDisabled = true;
          } else {
            vData.buttonDisabled = false;
          }
        })
        .catch(error => {
          console.log(error);
          this.buttonDisabled = false;
        });
    },
    async toDemographicsPage() {
      const isInputValid = await this.$refs.form.$parent.validate();
      if (isInputValid) {
        this.$router.push({
          name: "Demographics",
          params: {
            fName: this.contactData.fName,
            lName: this.contactData.lName,

            street1: this.contactData.address.street1,
            street2: this.contactData.address.street2,
            city: this.contactData.address.city,
            state: this.contactData.address.state,
            zipCode: this.contactData.address.zipCode,

            email: this.contactData.email,
            phoneNum: this.contactData.phoneNum,
            phoneExt: this.contactData.phoneExt,
            cityEmployee: this.contactData.cityEmployee,
            RIFKnowledge: this.RIFKnowledge,
            RIFKnowledgeOther: this.RIFKnowledgeOther,
            dateOfBirth: this.contactData.dateOfBirth,
            preferredContact: this.contactData.preferredContact
          }
        });
      }
    },
    getAddressCandidatesFromAis() {
      var vData = this;
      vData.AddressCandidatesLoaded = false;
      vData.ShowCandidateTable = true;

      const aisUrl = `https://api.phila.gov/ais/v1/search/${vData.contactData.address.rawAddress}?gatekeeperKey=2d130e3603f8931d82d39b3913bffe18`;
      delay(() => {
        axios.get(aisUrl).then(result => {
          if (result && result.data) {
            vData.contactData.address.candidates = result.data.features.map(
              (i, idx) => ({
                id: idx,
                StreetAddress: i.properties.street_address,
                ZipCode: i.properties.zip_code,
                selected: false
              })
            );
            vData.AddressCandidatesLoaded = true;
          } else {
            vData.ShowCandidateTable = false;
            vData.contactData.address.candidates = [];
            vData.contactData.address.candidates = {};
          }
        });
      }, 1000);
    },
    setSelectedAddressCandidate(item) {
      var vData = this;
      vData.contactData.address.street1 = item.StreetAddress;
      vData.contactData.address.zipCode = item.ZipCode;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

/* Choose file button */
.file-upload-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.file-list {
  flex-grow: 1;
  margin-right: 1rem;
}

#upload-documents {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

#upload-documents li {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.file-upload {
  flex-shrink: 0;
}

.remove-button {
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
  margin-left: 0.5rem;
}

.document-section {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
}

.add-more-documents {
  margin-top: 20px;
  text-align: center;
}

/* for checkbox */
.left_contentlist {
  margin-top: 1.5%;
  width: 35%;
  position: relative;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f5f5f5;
  margin-bottom: 10px;
}

.itemconfiguration {
  max-height: 200px;
  width: 100%;
  overflow-y: auto;
  position: relative;
}

/* Style for the checkbox container */
.vee-checkbox {
  display: block;
  margin-bottom: 5px;
}

/* Style for the label */
.vee-checkbox label {
  display: block;
  padding: 5px;
  margin-bottom: 2px;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  cursor: pointer;
}

/* Style for the checkbox */
.vee-checkbox input[type="checkbox"] {
  margin-right: 5px;
}

/* Scrollbar styles for webkit browsers */
.itemconfiguration::-webkit-scrollbar {
  width: 8px;
}

.itemconfiguration::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.itemconfiguration::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.itemconfiguration::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
